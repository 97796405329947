import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { renderBlocks } from "../js/pageBlockRender";

import Layout from "../components/global/Layout";
import SEO from "../components/global/SEO";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      homepage: wordpressPage(title: { eq: "Home" }) {
        title
        id
        yoast {
          focuskw
          title
          metadesc
          opengraph_title
          opengraph_description
          opengraph_updated_time
          twitter_title
          twitter_description
          linkdex
          metakeywords
          meta_robots_noindex
          meta_robots_nofollow
          meta_robots_adv
          canonical
          redirect
          opengraph_url
          opengraph_site_name
          opengraph_type
        }
        acf {
          page_blocks {
            block_type {
              acf_fc_layout

              fwtt_eyebrow
              fwtt_alignment
              fwtt_title
              fwtt_text

              dl_style

              hh_title
              hh_copy
              hh_background_video
              hh_poster {
                alt_text
                localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 4000) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
              }

              csg_hide_toggle
              csg_context
              csg_case_studies {
                case_study {
                  post_title
                  post_type
                  post_content
                  post_excerpt
                  categories
                  acf {
                    case_study_key_properties
                    case_study_blocks {
                      block_type {
                        acf_fc_layout
                        csh_title
                      }
                    }
                  }
                }
                size
                title_color
                image {
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 4000) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                overlay_bg_color
                content_va
                content_ha
              }
              media_item {
                media_item_image_or_video
                media_item_size
                media_video_autoplay_or_click
                media_video_url
                media_image {
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 4000) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              q_intro
              q_quote
              q_quotee

              bi_business_intro
              bi_business_contact

              tpc_thought_piece {
                post_title
                post_excerpt
                
                image {
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 4000) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                acf {
                  legacy
                  thought_piece_blocks {
                    block_type {
                      acf_fc_layout
                      authors {
                        a_company
                        a_headshot {
                          alt_text
                          localFile {
                            childImageSharp {
                              fluid(quality: 100, maxWidth: 1000) {
                                ...GatsbyImageSharpFluid_withWebp
                              }
                            }
                          }
                        }
                        a_name
                        a_title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO yoast={data.homepage.yoast} />
      {renderBlocks(
        data.homepage.acf !== null ? data.homepage.acf.page_blocks : "",
        data
      )}
    </Layout>
  );
};

export default IndexPage;
